<template>
    <!-- <div class="contact">

        <div class="contactLeft">
               <img class="image" src="../assets/therapie/therapie.svg" alt="art_therapie" />

        </div>

        <div class="contactRight">
     
        </div>

    </div> -->


        <h1>Elisabeth Terrière</h1>
        <h2>Casa Bien-Être</h2>

</template>
    
    
<script setup>
    
</script>

    
<style scoped>
.image {
    margin-bottom: 30px;
    height: 100px;
    width: 100x;
}

.contact {
    width: 100%;
    display: inline-flex;
}

.contactLeft {
    float: left;
    width: 20%;
    margin-right: 5%;
}

.contactRight {
    float: right;
    width: 70%;
    margin-left: 5%;
}
</style>