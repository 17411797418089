<template>
  <Header />

  <NavBar />
  <router-view :mail="mail" :displayPhone="displayTel" :phone="tel" />

  <Footer :mail="mail" :displayPhone="displayTel" :phone="tel" />

</template>

<script setup>
import NavBar from './components/NavBar.vue'
import Footer from './components/FooterPage.vue'
import Header from './components/HeaderPage.vue'

const mail = "elisabeth@casa-bienetre.fr"
const tel = "+33661977561"
const displayTel = "06 61 97 75 61"

</script>

<style>

.body {
  background-color: var(--background-color);
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  text-align: center;
}
</style>